<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <div class="card mt-5 mt-xxl-8">
          <!--begin::Card head-->
          <div class="card-header card-header-stretch">
            <!--begin::Title-->
            <div class="card-title d-flex align-items-center">
              <!--begin::Svg Icon | path: icons/duotone/Interface/Calendar.svg-->
              <span class="svg-icon svg-icon-1 svg-icon-primary me-3 lh-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.25"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 3C6 2.44772 6.44772 2 7 2C7.55228 2 8 2.44772 8 3V4H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H6V3Z"
                    fill="#191213"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14H17C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12H10ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H13C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16H7Z"
                    fill="#121319"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->
              <h3 class="fw-bolder m-0 text-gray-800">ดำเนินการ</h3>
            </div>
            <!--end::Title-->
            <!--begin::Toolbar-->
            <div class="card-toolbar m-0">
              <!--begin::Tab nav-->
              <ul
                class="
                  nav nav-tabs nav-line-tabs nav-stretch
                  fs-6
                  border-0
                  fw-bolder
                "
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <a
                    style="cursor: pointer"
                    class="
                      nav-link
                      justify-content-center
                      text-active-gray-800
                      active
                    "
                    >ดำเนินการ ({{ taxs.length }})</a
                  >
                </li>
              </ul>

              <!--end::Tab nav-->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card head-->
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Tab Content-->
            <div class="tab-content">
              <!--begin::Tab panel-->
              <div
                class="card-body p-0 tab-pane fade show active table-responsive"
              >
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_table_users"
                  style="border-collapse: unset"
                >
                  <!--begin::Table head-->
                  <thead>
                    <!--begin::Table row-->

                    <tr
                      class="
                        text-start text-gray-400
                        fw-bolder
                        fs-7
                        text-uppercase
                        gs-0
                      "
                    >
                      <th class="min-w-125px">หัวข้อ</th>
                      <th class="min-w-125px">เรื่องที่สอบถาม</th>
                      <th class="min-w-125px">ชื่อผู้แจ้ง</th>
                      <th class="min-w-125px">สถานะ</th>
                      <th class="min-w-125px">วันเวลาที่แจ้ง</th>

                      <th class="text-end min-w-100px">จัดการ</th>
                    </tr>
                    <!--end::Table row-->
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody
                    class="text-gray-600 fw-bold"
                    v-if="taxs.length > 0"
                  >
                    <!--begin::Table row-->
                    <template v-for="tax in taxs" :key="tax.id">
                      <tr>
                        <td>{{ tax.title }}</td>
                        <td>{{ tax.sub_title }}</td>
                        <td>{{ tax.name_user }}</td>
                        <td>{{ tax.status }}</td>
                        <td>{{ dateThai(tax.created_at) }}</td>
                        
                        <!--begin::Action=-->
                        <td class="text-center">
                          <router-link
                            :to="{
                              name: 'apps.taxs.taxs.show',
                              params: { id: tax.id },
                            }"
                          >
                            <a
                              class="
                                btn btn-light btn-active-light-primary btn-sm
                              "
                              >จัดการ
                            </a>
                          </router-link>
                        </td>
                        <!--end::Action=-->
                      </tr>
                      <!--end::Table row-->
                    </template>
                  </tbody>
                  <tbody class="text-gray-600 fw-bold" v-else>
                    <tr>
                      <td colspan="7" class="text-center">ยังไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                v-bind:meta="meta"
                @pagination="updateHandler"
              ></Pagination>
              <!--end::Table-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import {
  MenuComponent,
  ToggleComponent,
  DrawerComponent,
} from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
import useTaxs from "@/core/services/api/tax/taxs";
import { useAbility } from "@casl/vue";
import moment from "moment";
import useSweetalert from "@/core/helpers/sweetalert2";
export default defineComponent({
  name: "Tax_Processing",
  components: {
    Pagination,
  },
  setup() {
    const taxs = ref([]);
    const meta = ref([]);
    const { getTax_Processing } = useTaxs();
    const { SconfirmNc } = useSweetalert();

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const { can } = useAbility();
    onMounted(() => {
      getTax_Processing()
        .then((response) => {
          taxs.value = response.data.data;
          meta.value = response.data.meta;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
      checkPage("tax-list");
       setCurrentPageBreadcrumbs("ดำเนินการ", [
        "สอบถามภาษี",
      ]);
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", HH : ss");
    }

    async function updateHandler(e) {
      isLoading.value = true;
      getTax_Processing(e)
        .then((response) => {
          taxs.value = response.data.data;
          meta.value = response.data.meta;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
    }

    return {
      can,
      taxs,
      meta,
      dateThai,
      updateHandler,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped></style>
